import { storyblokEditable } from '@storyblok/react'
import { useRef, useState } from 'react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { Carousel, CarouselRef } from '@/blocks/ReviewsV2/Carousel'
import { Button } from '@/components/Button'
import { ReviewsV2Storyblok } from '@/types/storyblok-component-types'
import { cn } from '@/utils/cn'

export default function Reviews({ blok }: { blok: ReviewsV2Storyblok }) {
  return (
    <div className="py-16" {...storyblokEditable(blok)}>
      <DesktopReviewsBody blok={blok} />
      <SmallDevicesReviewsBody blok={blok} />
    </div>
  )
}

const DESKTOP_ITEM_WIDTH = 360
const ITEM_GAP = 12

function DesktopReviewsBody({ blok }: { blok: ReviewsV2Storyblok }) {
  const carouselRef = useRef<CarouselRef>(null)

  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <div className="flex flex-row gap-12 px-12 items-center max-lg:hidden">
      <div className="flex flex-col gap-4">
        <RichTextParagraphStyles>
          <StoryblokRichText document={blok.content} />
        </RichTextParagraphStyles>
        <div className="flex flex-row gap-4">
          <Button
            variant="senary"
            className="p-1 size-9"
            disabled={currentIndex === 0}
            onClick={() => {
              carouselRef.current?.scrollToIndex(currentIndex - 1)
            }}
          >
            <MdChevronLeft className="size-8" />
          </Button>
          <Button
            variant="senary"
            className="p-1 size-9"
            disabled={currentIndex === blok.items.length - 1}
            onClick={() => {
              carouselRef.current?.scrollToIndex(currentIndex + 1)
            }}
          >
            <MdChevronRight className="size-8" />
          </Button>
        </div>
      </div>
      <div
        style={{
          width: DESKTOP_ITEM_WIDTH * 2,
        }}
        className="relative"
      >
        <Carousel
          ref={carouselRef}
          items={blok.items}
          onIndexChange={setCurrentIndex}
          itemWidth={DESKTOP_ITEM_WIDTH}
          itemGap={ITEM_GAP}
        />
        <div className="absolute left-0 top-0 bottom-0 bg-gradient-to-r from-white to-transparent w-32 pointer-events-none" />
        <div className="absolute right-0 top-0 bottom-0 bg-gradient-to-l from-white to-transparent w-32 pointer-events-none" />
      </div>
    </div>
  )
}

const MOBILE_ITEM_WIDTH = 300

function SmallDevicesReviewsBody({ blok }: { blok: ReviewsV2Storyblok }) {
  const carouselRef = useRef<CarouselRef>(null)
  const [currentIndex, setCurrentIndex] = useState(0)

  const dotsCount = blok.items.length

  return (
    <div className="flex flex-col gap-4 items-center lg:hidden">
      <div className="text-center px-4">
        <RichTextParagraphStyles>
          <StoryblokRichText document={blok.content} />
        </RichTextParagraphStyles>
      </div>
      <div className="flex flex-col gap-6">
        <div className="w-screen overflow-hidden">
          <Carousel
            ref={carouselRef}
            items={blok.items}
            onIndexChange={setCurrentIndex}
            itemWidth={MOBILE_ITEM_WIDTH}
            itemGap={ITEM_GAP}
          />
        </div>
        <div className="flex flex-row justify-center gap-4">
          {Array.from({ length: dotsCount }).map((_, index) => {
            return (
              <button
                key={index}
                onClick={() => {
                  carouselRef.current?.scrollToIndex(index)
                }}
                className={cn(
                  'size-2 bg-gray-200 rounded-full shrink-0',
                  currentIndex === index && 'bg-primary',
                )}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
